<template>
  <div class="usdtVideoCourse">
    <div v-title data-title="比特派"></div>
    <div class="Navbar">
      <img @click="gotoback" :src="imgUrl + 'left-arrow2.png'" />
      <div class="title">
        <ul style=" display: flex;">
          <li :class="activeIndex == 1 ? 'active' : ''" @click="changIndex(1)">
            比特派购买USDT
          </li>
          <li :class="activeIndex == 2 ? 'active' : ''" @click="changIndex(2)">
            存款到贝宝
          </li>
          <li :class="activeIndex == 3 ? 'active' : ''" @click="changIndex(3)">
            取款/出售USDT
          </li>
        </ul>
      </div>
    </div>

    <div class="tabs" v-show="activeIndex == 1">
      <div class="tabs-box">
        <div @click="changtabs(1)" :class="tabsactive == 1 ? 'tabsactive' : ''">USDT泰达币介绍</div>
        <div @click="changtabs(2)" :class="tabsactive == 2 ? 'tabsactive' : ''">下载注册教程</div>
      </div>
      <div class="test_two_box" v-show="tabsactive == 1">
        <video id="myVideo1" class="video-js" style="width:100%">
          <source :src="`${videoUrl}usdtBriefing.mp4`" type="video/mp4" />
        </video>
      </div>
      <div class="test_two_box" v-show="tabsactive == 2">
        <video id="myVideo2" class="video-js" style="width:100%">
          <source :src="`${videoUrl}usdtRegister.mp4`" type="video/mp4" />
        </video>
      </div>
    </div>

    <div class="tabs" v-show="activeIndex == 2">
      <div class="tabs-box">
        <div @click="changtabs(1)" :class="tabsactive == 1 ? 'tabsactive' : ''">如何购买USDT</div>
        <div @click="changtabs(2)" :class="tabsactive == 2 ? 'tabsactive' : ''">如何卖出USDT</div>
        <div @click="changtabs(3)" :class="tabsactive == 3 ? 'tabsactive' : ''">查询买卖记录</div>
      </div>
      <div class="test_two_box" v-show="tabsactive == 1">
        <video id="myVideo3" class="video-js" style="width:100%">
          <source :src="`${videoUrl}buyUsdt.mp4`" type="video/mp4" />
        </video>
      </div>
      <div class="test_two_box" v-show="tabsactive == 2">
        <video id="myVideo4" class="video-js" style="width:100%">
          <source :src="`${videoUrl}sellUsdt.mp4`" type="video/mp4" />
        </video>
      </div>
      <div class="test_two_box" v-show="tabsactive == 3">
        <video id="myVideo5" class="video-js" style="width:100%">
          <source :src="`${videoUrl}queryDealRecord.mp4`" type="video/mp4" />
        </video>
      </div>
    </div>

    <div class="tabs" v-show="activeIndex == 3">
      <div class="tabs-box">
        <div @click="changtabs(1)" :class="tabsactive == 1 ? 'tabsactive' : ''">如何接收USDT</div>
        <div @click="changtabs(2)" :class="tabsactive == 2 ? 'tabsactive' : ''">如何发送USDT</div>
        <div @click="changtabs(3)" :class="tabsactive == 3 ? 'tabsactive' : ''">公链查看交易</div>
      </div>
      <div class="test_two_box" v-show="tabsactive == 1">
        <video id="myVideo6" class="video-js" style="width:100%">
          <source :src="`${videoUrl}acceptUsdt.mp4`" type="video/mp4" />
        </video>
      </div>
      <div class="test_two_box" v-show="tabsactive == 2">
        <video id="myVideo7" class="video-js" style="width:100%">
          <source :src="`${videoUrl}sendUsdt.mp4`" type="video/mp4" />
        </video>
      </div>
      <div class="test_two_box" v-show="tabsactive == 3">
        <video id="myVideo8" class="video-js" style="width:100%">
          <source :src="`${videoUrl}queryUsdt.mp4`" type="video/mp4" />
        </video>
      </div>
    </div>
    <p style="color:var(--li-colortext);">视频教学</p>
    <p style="color:var(--li-colortext);">点击播放按钮观看教学视频</p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeIndex: 1,
      tabsactive: 1,
    };
  },
  mounted () {
    this.initVideo();
  },
  methods: {
    gotoback () {
      this.$router.go(-1)
      // uni.navigateBack({});
    },
    initVideo () {
      //初始化视频方法
      let myPlayer = this.$video(myVideo1, {
        controls: true,
        autoplay: "muted",
        preload: "auto",
        height: "200px"
      });

      let myPlayer2 = this.$video(myVideo2, {
        controls: true,
        autoplay: "muted",
        preload: "auto",
        height: "500px"
      });

      let myPlayer3 = this.$video(myVideo3, {
        controls: true,
        autoplay: "muted",
        preload: "auto",
        height: "500px"
      });

      let myPlayer4 = this.$video(myVideo4, {
        controls: true,
        autoplay: "muted",
        preload: "auto",
        height: "500px"
      });

      let myPlayer5 = this.$video(myVideo5, {
        controls: true,
        autoplay: "muted",
        preload: "auto",
        height: "500px"
      });

      let myPlayer6 = this.$video(myVideo6, {
        controls: true,
        autoplay: "muted",
        preload: "auto",
        height: "500px"
      });

      let myPlayer7 = this.$video(myVideo7, {
        controls: true,
        autoplay: "muted",
        preload: "auto",
        height: "500px"
      });

      let myPlayer8 = this.$video(myVideo8, {
        controls: true,
        autoplay: "muted",
        preload: "auto",
        height: "500px"
      });
    },
    changIndex (index) {
      if (index == this.activeIndex) return;

      this.tabsactive = 1;
      this.activeIndex = index;
    },

    changtabs (index) {
      if (index == this.tabsactive) return;
      this.tabsactive = index;
    }
  },
};
</script>

<style lang="less">
body {
  background-color: #f5f5f5;
}

.usdtVideoCourse {
  .Navbar {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 43px;
    width: 100%;
    z-index: 100;
    background-color: #fff;
    padding-left: 10px;

    >img {
      height: 20px;
      width: 20px;
    }

    .title {
      font-size: 13px;
      flex: 1;
      text-align: center;
      color: #000;

      ul {
        height: 43px;
      }

      li {
        height: 43px;
        line-height: 43px;
        margin: 0 10px;
      }

      .active {
        color: #5780e9;
        border-bottom: 2px solid #5780e9;
      }
    }
  }

  .test_two_box {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 40px;
    padding: 0 20px;
  }

  p {
    text-align: center;
    color: #000;
    font-size: 14px;
  }

  .tabs {
    margin: 64px 20px 0px 20px;

    .tabs-box {
      display: flex;
      border: 1px solid #5780e9;
      border-radius: 6px;

      >div {
        flex: 1;
        padding: 8px 6px;
        text-align: center;
        font-size: 13px;
        color: var(--li-colortext);
        ;
      }

      .tabsactive {
        background-color: #6386fe;
        color: #fff;
      }
    }
  }
}
</style>
